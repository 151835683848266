.containerInner {
  align-items: center;
  display: flex;
  flex-direction: column;
  padding-bottom: 80px;
  padding-top: 24px;
}

.errorMessage {
  margin-top: 24px;
}

.form {
  align-items: stretch;
  display: flex;
  flex-direction: column;
  width: 500px;
}

.input {
  margin-top: 32px;
}

.rows {
  display: flex;
  flex-direction: column;
  margin-top: 64px;
  row-gap: 16px;
}

.submitButton {
  height: 56px;
  margin-top: 24px;
}
