.header {
  align-items: center;
  display: flex;
  justify-content: space-between;
  padding-bottom: 23px;
  padding-top: 27px;
}

.right {
  align-items: center;
  column-gap: 60px;
  display: flex;
}

@media only screen and (max-width: 1024px) {
  .logo {
    width: 300px;
  }
}