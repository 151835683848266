.royalties {
  display: flex;
  flex-direction: column;
  margin-top: 12px;
  row-gap: 24px;
}

.royaltiesLabels {
  align-self: stretch;
  column-gap: 24px;
  display: flex;
}

.royaltiesLabelsLeft {
  flex-grow: 1;
}

.royaltiesLabelsRight {
  /* Must watch walletPercent width */
  width: 100px;
}

.royaltiesRow {
  column-gap: 24px;
  display: flex;
}

.walletAddress {
  flex-grow: 1;
}

.walletPercent {
  width: 100px;
}