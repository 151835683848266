.description {
  color: var(--color-secondary);
  margin-bottom: 8px;
  margin-top: 6px;
}

.label {
  color: var(--color-primary);
}

.labels {
  display: flex;
  flex-direction: column;
  margin-bottom: 12px;
  row-gap: 8px;
}

.hint {
  margin-top: 6px;
}

.subLabel {
  color: var(--color-secondary);
}
