.errorMessage {
  margin-top: 24px;
  text-align: center;
}

.form {
  align-items: stretch;
  display: flex;
  flex-direction: column;
}

.inputs {
  align-items: stretch;
  display: flex;
  flex-direction: column;
  row-gap: 24px;
  min-width: 300px;
}

.mintButton {
  margin-top: 48px;
}
