.containerInner {
  align-items: center;
  display: flex;
  flex-direction: column;
  padding-bottom: 80px;
  padding-top: 24px;
}

.content {
  align-items: center;
  display: flex;
  flex-direction: column;
}

.description {
  margin-top: 24px;
}

.dropzone {
  align-items: center;
  border: 2px dashed var(--color-purple);
  border-radius: 24px;
  display: flex;
  flex-direction: column;
  height: 500px;
  justify-content: center;
  margin-top: 32px;
  padding-bottom: 48px;
  padding-top: 48px;
  width: 500px;
}

.form {
  margin-top: 48px;
}

.photo {
  border-radius: 24px;
  height: 400px;
  max-width: 400px;
  object-fit: contain;
}