.address {
  text-align: left;
  width: 130px;
}

.container {
  align-items: center;
  column-gap: 32px;
  display: flex;
}

.containerOuter {
  display: flex;
  flex-direction: column;
  row-gap: 8px;
}